document.addEventListener('DOMContentLoaded', ()=> {
    //Конечная дата, (JS месяцы c 0 индекса)
    const deadline = new Date(2022, 5, 30);
    //id таймера
    let timerId = null;
    //Склонение числительных
    function declensionNum(num, words) {
        return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
    }
    //Вычисляем разницу дат и устанавливаем оставшееся времени в качестве содержимого элементов
    function countdownTimer() {
        const diff = deadline - new Date();
        if (diff <= 0) {
            clearInterval(timerId);
        }
        const months = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24 / 30) : 0;
        const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) % 30: 0;
        const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
        const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
        const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
        //Устанавливаем разрядность
        $months.textContent = months < 10 ? '0' + months : months;
        $days.textContent = days < 10 ? '0' + days : days;
        $hours.textContent = hours < 10 ? '0' + hours : hours;
        $minutes.textContent = minutes < 10 ? '0' + minutes : minutes;
        $seconds.textContent = seconds < 10 ? '0' + seconds : seconds;
        //Выясняем наименования
        $months.dataset.title = declensionNum(months, ['месяц', 'месяца', 'месяцев']);
        $days.dataset.title = declensionNum(days, ['день', 'дня', 'дней']);
        $hours.dataset.title = declensionNum(hours, ['час', 'часа', 'часов']);
        $minutes.dataset.title = declensionNum(minutes, ['минута', 'минуты', 'минут']);
        $seconds.dataset.title = declensionNum(seconds, ['секунда', 'секунды', 'секунд']);
    }
    //Получаем элементы, содержащие компоненты даты
    const $months = document.querySelector('.timer__months');
    const $days = document.querySelector('.timer__days');
    const $hours = document.querySelector('.timer__hours');
    const $minutes = document.querySelector('.timer__minutes');
    const $seconds = document.querySelector('.timer__seconds');
    //Вызываем функцию countdownTimer
    countdownTimer();
    //Вызываем функцию countdownTimer каждую секунду
    timerId = setInterval(countdownTimer, 1000);
});