document.addEventListener('DOMContentLoaded', () =>{

//Работа с формой
//     const form = document.getElementById('form')

    //На все формы вешает слушатель сабмита
    const forms = document.getElementsByTagName("form")
    for (let i = 0; i < forms.length; i++) {
        forms[i].addEventListener('submit', (event)=>{
            formSend(event)
        })
    }

    async function formSend(event){
        event.preventDefault()
        let form = event.target
        let error = formValidate(form)
        let formData = new FormData(form)

        let url =  "./rostika_bot.php?"

        // let dataToSend = {};
        formData.forEach((value, key) =>{

            url += `${key}=${value}&`

            // dataToSend[key] = value;
            // if (key === "form_num") dataToSend[key] = Number(value);
        });

        // url = url.replace('+','')
        url = url.substring(0, url.length-1)

        // console.log(url)

        // let json = JSON.stringify(object);


        // console.log('error:', error)
        // console.log('f data:', ...formData)
        // console.log('d to send:', dataToSend)


        if (error === 0) {
            form.classList.add('_sending');
            // console.log('send mail start')

            let response = await fetch(url);
/*
            // let response = await fetch('https://матрица38.рф/scripts/rostika_bot.php', {
            let response = await fetch('../../../sendmail.php', {
                method: "POST",
                // mode: 'no-cors', // no-cors, *cors, same-origin
                body: JSON.stringify(dataToSend)
                // body: dataToSend
            });

 */

            console.log('response', response)


            if (response.ok){
                let result = await response.json();
                alert('Данные успешно отправлены в кофейную компанию \"Rostika\"');
                form.reset()
                form.classList.remove('_sending')
            } else {
                // alert('Ошибка HTTP:' + response.status)
                alert('При отправке информации возникла ошибка. HTTP:' + response.status)
                // console.log(response)
                form.classList.remove('_sending')
            }

        }else {
            alert('Обязательные поля не заполнены, либо заполнены неверно!')
        }
    }

    function formValidate(form) {
        let error = 0;

        //Получаем необходимые поля для конкретной формы
        let formRequirements = Array.from(document.querySelectorAll('._req')).filter((element)=>{
            return element.form === form
        })


        for (let index = 0; index < formRequirements.length; index++){
            const input = formRequirements[index]
            formRemoveError(input)

            if (input.value === '') {
                formAddError(input);
                error++;
            } else if (input.classList.contains('_phone')){
                if(!phoneTest(input)){
                    formAddError(input);
                    error++;
                    console.log('phone error')
                }
            } else if (input.classList.contains('_email')){
                if(!emailTest(input)){
                    formAddError(input);
                    error++;
                    console.log('email error')
                }
            }
        }

        console.log('Count of errors from validation: ',error)

        return error
    }

    function formAddError(input){
        input.parentElement.classList.add('_error')
        input.classList.add('_error')
    }

    function formRemoveError(input) {
        input.parentElement.classList.remove('_error')
        input.classList.remove('_error')

    }

    //Функция теста e-mail
    function emailTest(input) {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value)
    }

    //Функция теста номера телефона
    function phoneTest(input) {
        const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return phoneRegExp.test(input.value)

    }

    function submitForm(event) {
        event.preventDefault();
        // console.log(event)
        // console.log(document.forms[0]);

    }

    let allSubmitButtons = document.querySelectorAll("._btn_submit")
    for (let i = 0; i < allSubmitButtons.length; i++){
        allSubmitButtons[i].addEventListener('click', (event)=>{
            event.preventDefault()
            //Присваиваем инпату типа значение по назначению кнопки
            //form_num - номер формы:
            // 0 - Закажите звонок
            // 1 - Акция
            // 2 - получить оптовый прайс
            // 3 - Оставить заявку
            event.target.value
                ? document.getElementById('form4')[0].value = event.target.value
                : event.target.form.requestSubmit()

            console.log(document.getElementById('form4')[0].value)



        })
    }

})


