const popupLinks = document.querySelectorAll('.popup-link')
const body = document.body
const lockPadding = document.querySelectorAll('.lock-padding')



let unlock = true

const timeout = 800

if (popupLinks.length > 0) {
    popupLinks.forEach(popupLink => {
        popupLink.addEventListener('click', (e) => {
            const popupName = popupLink.getAttribute('href').replace('#','')
            const currentPopup = document.getElementById(popupName)
            popupOpen(currentPopup)
            e.preventDefault()
        })

    })

}

const popupCloseIcon = document.querySelectorAll('.close-popup')
if (popupCloseIcon.length > 0) {
    popupCloseIcon.forEach(elem => {
        elem.addEventListener('click', (e) => {
            popupClose(elem.closest('.popup'))
            e.preventDefault()
        })
    })
}

function popupOpen(currentPopup) {
    if (currentPopup && unlock) {
        const popupActive = document.querySelector('.popup._open')
        if (popupActive) {
            popupClose(popupActive, false)
        } else {
            bodyLock()
        }
    currentPopup.classList.add('_open')
    currentPopup.addEventListener('click', (e) => {
        if (!e.target.closest('.popup_content')) {
            popupClose(e.target.closest('.popup'))
        }
    })
    }
    currentPopup.addEventListener('contextmenu', (e) => {
        e.preventDefault()
    })
}

function popupClose (popupActive, doUnlock = true){
    if (unlock) {
        popupActive.classList.remove('_open')
        if (doUnlock) {
            bodyUnlock()
        }
    }

}

function bodyLock() {
    const lockPaddingValue = window.innerWidth - document.querySelector('.wrapper').offsetWidth + 'px'
    if (lockPadding.length > 0) {
        for (let i = 0; i < lockPadding.length; i++) {
            const el = lockPadding[i]
            el.style.paddingRight = lockPaddingValue
        }
    }
    body.style.paddingRight = lockPaddingValue
    body.classList.add('_lock')


    unlock = false
    setTimeout (() => {
            unlock = true;
    }, timeout)
}

function bodyUnlock() {
    setTimeout(() => {
        for (let i = 0; i < lockPadding.length; i++) {
            const el = lockPadding[i]
            el.style.paddingRight = 0;
        }
        body.style.paddingRight = 0;
        body.classList.remove('_lock')
    }, timeout)

    unlock = false
    setTimeout(() => {
        unlock = true
    }, timeout)
}

window.addEventListener('keydown', (e) => {
    if(e.key === 'Escape' ) {
        const popupActive = document.querySelector('.popup._open')
        popupClose(popupActive)
    }
})

    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            let el = this;

            do {
                if (Element.prototype.matches.call(el, s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }

(function () {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector;

    }
})()




//Попап перез закрытием - кошмар для UX
// let message = "Останьтесь и посмотрите наше крайнее предложение";
//
// window.addEventListener('beforeunload', (event)=> {
//     // event.preventDefault()
//     popupOpen(document.getElementById('popup4'))
//
//
//
//     if (typeof event == "undefined") {
//         event = window.event;
//     }
//     if (event) {
//         event.returnValue = message;
//     }
//     // return message;
//     // delete event['returnValue'];
//     console.log(event)
//     return message
// })

// window.addEventListener('unload', (event)=> {
//     // event.preventDefault()
//     return message
// })


