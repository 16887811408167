console.log('carousel include')
document.addEventListener("DOMContentLoaded", (event)=>  {


    let change_img_time = 7000
    let isReadyToClick = true
    //TODO prevent multi click

    const listItems = Array.from(document.getElementsByClassName('carousel')[0].children)
    const dotItems = Array.from(document.querySelector('.dots').children)
    let scoreField = document.querySelector('.score')

    let currentCarouselItem

    let listLen = listItems.length,
        i = 0,
        prevIndex = listLen-1,
        interval;

    let dir = 1
    let playToggle = true

    function cycle(){

        //работаем с классами дотов
        dotItems.map((element, index)=> {
            element.classList.remove('active__dot')
            element.classList.remove('active')
            if (i === index) {
                element.classList.add('active__dot')
                element.classList.add('active')
            }
        })

        //работаем с элементами

        listItems.map((element, index)=> {
            element.classList.remove('active__item')

            if (i === index) { //текущий
                element.classList.add('active__item')
                element.style.zIndex = '2'
                element.style.opacity = '1'
                element.style.left = '0';
                currentCarouselItem = element
            } else if (index === prevIndex){ //предыдущий
                element.style.left = dir*-element.offsetWidth + 'px'
                element.style.zIndex = '1'
                element.style.opacity = '1'
            } else  { //следующий
                element.style.left = dir*element.offsetWidth + 'px'
                element.style.zIndex = '-1'
                element.style.opacity = '0'
            }
        })

        scoreField.innerHTML = `${i+1}/${listLen}`
        prevIndex = i
        i = ++i % listLen
        dir = 1

        clearTimeout(interval);
        interval = setTimeout(function() { cycle(); }, change_img_time);




    };

    /**
     * Элементы управления каруселью
     */
    //Кнопка предыдущего элемента
    let prev = document.querySelector('.prev')
    prev.addEventListener('click', (event)=>{
        event.preventDefault()
         if (!isReadyToClick) return

        dir = -1
        let prIndex = listItems.indexOf(document.querySelector('.active__item')) - 1
        prIndex === -1 ? i = listLen-1 : i = prIndex
        cycle();
    })

    //Кнопка следующего элемента
    let next = document.querySelector('.next')
    next.addEventListener('click', (event)=>{
        event.preventDefault()
        dir = 1
        let nextIndex = listItems.indexOf(document.querySelector('.active__item')) + 1
        nextIndex === listLen ? i = 0 : i = nextIndex;


        cycle();
    })

    //Кнопка паузы карусели
    let pause = document.querySelector('.pause')
    pause.addEventListener('click', (event)=>{
        event.preventDefault()
        playToggle = !playToggle

        if (playToggle) {
            pause.innerHTML = `<i class='bx bx-pause' ></i>`
            clearTimeout(interval);
            interval = setTimeout(function() { cycle(); }, change_img_time);
        } else {
            pause.innerHTML = `<i class='bx bx-play' ></i>`
            clearTimeout(interval);
        }
    })

    //Панель переключения между дотами
    dotItems.map((element)=> {
        element.addEventListener('click', ()=> {
            i = dotItems.indexOf(element)
            cycle()
        })
    })

    cycle();

    console.log('carousel loaded')
});

